<template>
  <v-container fluid>
    <patient-filter
      :patients="patients"
      :panel="true"
      @filterChange="filterChange"
    ></patient-filter>

    <v-card outlined class="pb-2 mt-3">
      <v-card-title class="pa-5">
        <div style="display: contents">
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              outlined
              dense
              class="shrink search"
              :class="$vuetify.breakpoint.xsOnly ? 'seachXs' : ''"
              :placeholder="$t('search')"
              single-line
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              :items="billLockFilter"
              item-text="text"
              item-value="value"
              hide-details
              outlined
              dense
              class="shrink"
              v-model="filterIsLock"
              :label="$t('bills.billLock')"
            >
            </v-select>
          </v-col>
        </div>
      </v-card-title>
      <v-card-text>
        <data-table-component
          :headers="headers"
          :items="bills"
          :search="search"
          :loading="loading"
          :showFooter="true"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PatientFilter from "@/components/PatientFilter.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";

export default {
  components: { PatientFilter, DataTableComponent },

  data() {
    return {
      loading: true,
      show: false,
      type: "success",
      message: "Completed Successfully",
      search: "",
      bills: [],
      patients: [],

      editedItem: {
        voucherAmount: 0,
        note: "",
      },
      defaultItem: {
        voucherAmount: 0,
        note: "",
      },
      filterIsLock: 2,
      filterItem: {
        patientName: "",
        fromDate: this.$moment().format("YYYY-MM-DD"),
        toDate: this.$moment().format("YYYY-MM-DD"),
        billLock: 2,
      },

      billLockFilter: [
        { text: this.$t("bills.both"), value: 0 },
        { text: this.$t("bills.locked"), value: 1 },
        { text: this.$t("bills.notLocked"), value: 2 },
      ],
      rules: [
        (value) => !!value || "Required.",
        //(value) => (value && value.length <= 5) || "Max 5 characters",
      ],
    };
  },
  created() {
    this.loading = true;
    this.refreshTable();
    if (this.isInRole("28")) {
      this.$axios.get("Patient").then((response) => {
        this.patients = response.data.data;
      });
    }
  },
  watch: {
    filterIsLock(val) {
      this.filterItem.billLock = val;
      this.refreshTable();
    },
  },

  computed: {
    headers() {
      var list = [
        { text: this.$t("id"), value: "id" },
        { text: this.$t("bills.billCode"), value: "billCode" },
        {
          text: this.$t("patients.patientName"),
          value: "patientDisplayName",
        },
        {
          text: this.$t("procedures.totalAmount"),
          value: "totalAmount",
          type: "number",
          format: "0,0",
        },
        {
          text: this.$t("bills.paidAmounts"),
          value: "totalPaid",
          type: "number",
          format: "0,0",
        },
        {
          text: this.$t("bills.totalDiscount"),
          value: "totalDiscount",
          type: "number",
          format: "0,0",
        },
        {
          text: this.$t("bills.remainingAmount"),
          value: "remainingAmount",
          type: "number",
          format: "0,0",
        },
        {
          text: this.$t("date"),
          value: "billDate",
          type: "date",
          format: "YYYY/MM/DD hh:mm A",
        },
        //{ text: this.$t("bills.isLock"), value: "isLock" },
        { text: this.$t("bills.isPaid"), value: "isPaid", type: "checkbox" },
        //{ text: this.$t("notes"), value: "notex" },
      ];
      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };
      actions.templates.push({
        type: "btn",
        icon: "mdi-file-document-edit-outline",
        text: this.$t("patients.viewBillDetails"),
        click: this.billDetails,
        color: "primary",
      });

      actions.templates.push({
        type: "btn",
        icon: "mdi-printer",
        text: this.$t("print"),
        click: this.printItem,
      });

      list.push(actions);
      return list;
    },
  },

  methods: {
    refreshTable() {
      this.$axios
        .get("Bill/Get?filter=" + JSON.stringify(this.filterItem))
        .then((response) => {
          this.bills = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    billDetails(item) {
      this.$router.push("/billDetails/" + item.guid);
    },

    printItem(item) {
      let postConfig = {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
        responseType: "blob",
      };
      this.$axios
        .get("Bill/Print?guid=" + item.guid, postConfig)
        .then((response) => {
          if (response.status == 200) {
            var file = new Blob([response.data], { type: "application/pdf" });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          } else {
            this.$toast.error("Some thing went wrong");
          }
        })
        .catch((e) => {
          this.$toast.error("Some thing went wrong");
          console.log(e);
        });
    },

    filterChange(filterItem) {
      this.filterItem = filterItem;
      this.filterItem.billLock = this.filterIsLock;
      this.refreshTable();
    },
  },
};
</script>

<style></style>
